@import "../../../../styles/module-imports";

.iconsWrapper {
  display: flex;
  justify-content: flex-end;
}

.icon {
  @extend %iconWithCursor;
  margin-left: 15px;
}

.contentWrapper {
  border-right: 1px solid $grey;
  max-width: 160px;
  padding: 15px;
  text-align: center;
  cursor: pointer;

  @include x-large-down{
    border-right: none;
    max-width: none;
  }
}

.emailWrapper {
  border-right: 1px solid $grey;
  padding: 15px;
  cursor: pointer;
  @include x-large-down{
    border-right: none;
  }
}

.addStudentRowVisibie {
  display: contents;
}

.addStudnetRowInvisible {
  display: none;
}

.userIcon {
  width: 45px;
  border-radius: 50%;
}

.nameFont {
  font-size: 14px;
}
