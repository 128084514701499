@import "../../../../styles/module-imports";

.pointsRed {
  color: $vineyard-pink;
}

.pointsGreen {
  color: $vineyard-green;
}

.cellWrapper {
  border: none;
  text-align: center;
  padding: 20px;
  width: 200px;
}

.firstCellWrapper {
  border: none;
  align-self: center;
  width: 200px;
}

.popupFont {
  color: $vineyard-light-primiray;
  font-size: 12px;
}

.dateFont {
  font-weight: bold;
}

.cursor {
  cursor: pointer;
}

:global(.MuiPaper-elevation8) {
  box-shadow: 0px 3px 6px #00000014 !important;
}
