@import "../../../../styles/module-imports";

.courseOverviewWrapper {
  display: block;
  @include small-up {
    display: flex;
    height: 80px;
  }
}

.nameFont {
  font-size: 22px;
}

.mediumFont {
  font-size: 18px;
  font-weight: 500;
}

.courseDetailsSectionWrapper {
  height: 100%;
  border-left: 1px solid $grey-border-color;

  @include medium-down {
    border-left: none;
  }
}

.greyFontColor {
  color: #707070;
}

.greenText {
  padding-left: 10px;
  color: $vineyard-green;
  font-size: 18px;
  font-weight: 500;
}

.icon {
  @extend %icon;
  cursor: pointer;
}

.iconCell {
  @extend %iconCell;
  justify-content: flex-end;
  margin-top: 1.5rem;
}

.fileCursor {
  cursor: pointer;
}

.formWrapper {
  border-top: 1px solid $grey-border-color;
}

.borderRight {
  border-right: 1px solid $grey-border-color;
  @include medium-down {
    border-right: none;
  }
}

.accName {
  @extend %label;
  height: auto;
}

.rounded4 {
  border-radius: 25px;
  cursor: pointer;
}

.listLimitWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.courseActivityLimitWrapper {
  align-items: center;
  padding: 5px 10px;
  background-color: #9cde39;
  position: relative;
  flex-basis: auto;
  border-radius: 25px;
  margin-right: 5px;
  display: flex;
  width: auto;
  margin-bottom: 10px;
}

.noCursor {
  @extend %label;
  align-self: baseline;
  height: auto;
  width: auto;
  color: $white;
  font-size: 10px;
  cursor: auto;
}

.activityLimit {
  @extend %label;
  align-self: baseline;
  height: auto;
  width: auto;
  color: $white;
  font-size: 10px;
  cursor: pointer;
}

.deleteIcon {
  @extend %deleteIcon;
  cursor: pointer;
}

.progressBar {
  position: absolute;
  top: 50%;
  right: 50%;
  color: $vineyard-purple-primary;
  z-index: 1;
}
