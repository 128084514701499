@import "../../../../styles/module-imports";

.wrap {
  display: inline-block;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  height: calc(100vh - 200px);
  overflow-y: auto;
  background-color: $vineyard-grey-bg;
}
