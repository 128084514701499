@import "../../../../../../styles/module-imports";

.moveStudnetWrapper {
  width: 100%;
  background: $white;
  border-radius: 18px;
  opacity: 1;

  @include medium-up {
    width: 620px;
  }
}
