@import "../../../styles/module-imports";

.dialog {
  :global(.MuiDialog-paper) {
    overflow-y: clip;
  }
}

.errorDialog {
  color: $vineyard-error;
}
