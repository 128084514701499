@import "../../../../styles/module-imports";

.textWrapperStyle {
  font-size: 12px;
  color: #b5b4cb;
  margin-left: 20px;
  font-weight: 100;
  position: absolute;
  right: 0;
}

.textPoints {
  font-size: 13px;
}

.selectWrapper {
  border-bottom: 1px solid #eae1e1 !important;
  border-radius: 0px;
}

.alignItemsTable {
  text-align: center;
}

.innerTableHolder {
  width: 200px;
  display: inline-block;
  position: relative;
  padding-right: 100px;
}

.innerWrapperTable {
  width: 60px;
  position: absolute;
  right: 0;
  top: 0;
}

.buttonWrapper {
  max-width: 220px;
  width: 100%;
}

.table {
  @extend %table;

  td {
    @include x-large-down {
      width: 50% !important;

      &:first-child {
        width: 100% !important;
      }
    }

    @include small-down {
      width: 100% !important;
    }
  }
}
