@import "../../../../styles/module-imports";

.icon {
  @extend %icon;
  cursor: pointer;
}

.iconCell {
  @extend %iconCell;
  justify-content: flex-end;
}

.tableRow {
  cursor: pointer;
}

.tableCell {
  cursor: pointer;
}

.greenText {
  color: $vineyard-green;
}

.redText {
  color: $vineyard-pink;
}

.pendingColor {
  color: #9c4aee;
}

.category{
  font-size: 10px;
}

.applicationName {
  font-family: "Popins";
}