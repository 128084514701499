@import "../../../../styles/module-imports";

.iconsWrapper {
  @extend %iconCell;
  justify-content: flex-end;
}

.icon {
  @extend %iconWithCursor;
  margin-left: 15px;
}

.iconWithCursor {
  @extend %iconWithCursor;
}

.tableCol {
  cursor: pointer;
}

td[data-label="Section title"] {
  font-family: "Popins";
}
