@import "../../../../styles/module-imports";

.popup {
  cursor: pointer;
  padding-right: 2%;
  padding-left: 2%;
  // padding-bottom: 1%;
  // padding-top: 1%;
  overflow: hidden;
}

.icon {
  @extend %icon;
  cursor: pointer;
}

.listItemWrapper {
  @extend %horizontalWrapper;
  justify-content: baseline;
  align-items: center;
  border-bottom: 1px solid $grey-devider-color;
}

.activityView {
  font-size: $vineyard-font-very-small;
  color: $vineyard-green;
  cursor: pointer;
}

.iconView {
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.smallFont {
  font-size: $vineyard-font-small;
}

.modal {
  position: absolute;
  border: 1px solid $grey-devider-color;
  z-index: 1;
  width: 95%;
  margin-top: 25px;
}

.progressBar {
  :global(.MuiCircularProgress-circle) {
    color: $vineyard-purple-primary;
  }
}
