@import "./variables";
@import "./breakpoints";

%label {
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: $vineyard-purple-primary;
  opacity: 1;
}

%horizontalWrapper {
  width: 100%;
  display: flex;
}

%header {
  text-align: center;
  margin-bottom: 33px;
  font-size: 16px;
  letter-spacing: 0px;
  color: $vineyard-purple-primary;
  opacity: 1;
  font-family: Popins-Regular;
}

%icon {
  width: 14px;
  height: 14px;
  margin-left: 15px;
  @include x-large-down {
    width: 20px;
    height: 20px;
  }
}

%iconCell {
  display: flex;
  height: 40px;
  align-items: center;
}

%iconWithCursor {
  width: 14px;
  height: 14px;
  cursor: pointer;
  @include x-large-down {
    width: 20px;
    height: 20px;
  }
}

%card {
  width: 320px;
  background: $white;
  border-radius: 18px;
  opacity: 1;
  box-shadow: 0px 3px 6px #00000014;
  // position: absolute;
  // display: inline-block;
  // left: 0;
  @include medium-up {
    max-height: 800px;
  }
}

%deleteIcon {
  width: 10xp;
  height: 10px;
  margin-left: 12px;
}

%sortIcon {
  background: $vineyard-purple-primary;
  padding: 5px 3px;
  margin-left: 10px;
  border-radius: 5px;
}

%table {
  width: 100%;
  @include x-large-down {
    display: block;
    tbody {
      background-color: transparent;
      display: block;
    }
  }
  thead {
    border-bottom: 1px solid $grey-border-color;
    font-size: 12px;
    font-weight: bold;
    padding: 10px 10px;
    @include x-large-down {
      display: none;
    }
  }

  tr {
    @include x-large-down {
      box-shadow: 0px 1px 3px #00000012;
      border-radius: 15px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 15px;
      padding: 15px;
      background-color: $white;
    }

    &:first-child {
      // margin-bottom: 40px;
    }

    td {
      display: table-cell;
      vertical-align: middle;
      font-size: 14px;
      border-bottom: 1px solid $grey-border-color;
      padding: 10px;

      @include x-large-down {
        // display: block;
        text-align: left;
        width: 100%;
        display: flex;
        flex-direction: column;
        &::before {
          content: attr(data-label);
          color: $vineyard-light-primiray;
        }

        &:first-child {
          width: 100%;
        }
        &:last-child {
          border: none;
          width: 100%;
        }
      }

      p {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
  }
}
