@import "../../styles/module-imports";

.progressBar {
  position: absolute;
  top: 50%;
  right: 50%;
  color: $vineyard-purple-primary;
  z-index: 1;
}

.wrapper {
  // width: 250px;
}
