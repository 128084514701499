@import "../../styles/module-imports";

button:focus {
  outline: none;
}

.buttonWithImage {
  display: flex;
  justify-content: space-between;
  color: $white;
  background: $vineyard-purple-primary;
  border: 1px solid $grey-border-color;
  align-items: center;
  padding: 7px 23px;
  font-size: 12px;
  border-radius: 24px;
  font-family: Popins;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.5;
  }
}

.smallWithoutPadding {
  font-size: 10px;
  padding: 3px 10px;
  margin-top: 0px;
}

.iconSize {
  width: 10px;
  height: 10px;
  margin-left: 12px;
}

.bigIconSize {
  width: 15px;
  height: 15px;
}

.extraSmallButtonWrapper {
  margin-top: 0px;
}

.extraSmallButton {
  padding: 13px 10px;
  font-size: 14px;
  line-height: 14px;
  justify-content: space-around;
}

.isButtonWithoutText {
  padding: 7px;
  width: auto;
  min-width: auto;
}

.iconWithoutMargin {
  margin: 0;
}

.filterButton{
  background: #8e5fa3;
  font-size: 14px;
  @include medium-down {
    width: 100%;
  }
}

.addCourseButton {
  font-size: 14px;
  @include medium-down {
    width: 100%;
  }
}
