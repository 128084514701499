@import "../../styles/module-imports";

.selectContainer {
  width: 100%;
  height: 100%;
  position: relative;
  // margin-right: 15px;
  z-index: 1;
  border-radius: $vineyard-input-border-radius;

  :global(.MuiInputBase-root.Mui-focused) {
    border-bottom: unset;
  }
  :global(.MuiInput-underline:after) {
    border-bottom: unset;
  }
  :global(.MuiInput-underline:before) {
    border-bottom: unset;
  }
  :global(.MuiInput-underline:hover:not(.Mui-disabled):before) {
    border-bottom: unset;
  }
  :global(.MuiSelect-select) {
    border: 1px solid $grey-border-color;
    border-radius: $vineyard-input-border-radius;
  }
  :global(.MuiSelect-select:focus) {
    border-radius: $vineyard-input-border-radius;
    background: $white;
  }
  :global(.MuiInput-underline.Mui-disabled:before) {
    border-bottom-style: none !important;
  }

  @include small-down {
    // margin-bottom: 15px;
  }
}

select:focus {
  outline: none;
}

.label {
  @extend %label;
  position: relative;
  z-index: 1;
  height: 100%;
}

.displayNone {
  display: none;
}

.selectHolder {
  position: relative;
}

.errorMsg {
  position: absolute;
  font-size: $vineyard-font-very-small;
  color: $vineyard-error;
}

.errorWrapper {
  border-color: $vineyard-error;
}

.defaultOption {
  font-weight: bolder !important;
  display: none !important;
}

.select {
  margin-top: 10px;
  width: 100%;
  background: white;
  cursor: pointer;

  border-radius: $vineyard-input-border-radius;
  -webkit-appearance: none;
  -moz-appearance: none;

  .item {
    border-bottom: 1px solid $grey-border-color;
    &:last-of-type {
      border-bottom: none;
    }
  }

  :global(.MuiInputBase-input) {
    font-size: 14px;
    padding: 10px 20px;
    font-family: Popins;
  }
}

.smallSelect {
  :global(.MuiInputBase-input) {
    padding: 7px 20px;
  }
}

.loginSelectWrapper {
  width: 295px;
  height: auto;
  margin-top: $vineyard-text-input-margin;
  border-radius: $vineyard-input-border-radius;
  background: transparent;
}

.greenText {
  color: $vineyard-green;
}
