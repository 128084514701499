@import "../../../../../styles/module-imports";

.circle {
  width: 29px;
  height: 29px;
  line-height: 15px;
  color: white;
  border-radius: 50%;
  border: 2px solid transparent;
  background-color: $vineyard-light-primiray;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circleGray {
  background-color: $vineyard-green;
}
.stepBlock {
  position: relative;

  &:not(:last-child) {
    width: 100%;
  }
}
.stepBlock .circleWrapper {
  // padding: 0px 20px;
  height: 30px;
  width: 30px;
  position: relative;
  z-index: 1;
}

.selected .circle {
  color: $vineyard-green;
  background-color: white;
  border-color: $vineyard-green;
}

.stepBlock:not(:last-child)::after {
  content: "";
  width: 100%;
  height: 5px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin: auto;
}

.stepBlock:not(:last-child)::after {
  height: 4px;
  background-color: $vineyard-green;
}

.stepBlock:not(:last-child).selected::after,
.stepBlock:not(:last-child).selected ~ .stepBlock:not(:last-child)::after {
  height: 4px;
  background-color: $vineyard-light-primiray;
}
