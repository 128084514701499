@import "../../../../styles/module-imports";

.formWrapper {
  height: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include small-up {
    height: 100vh;
    margin-top: 0;
  }
}

.header {
  text-align: center;
  margin-bottom: 33px;
  font-size: $vineyard-font-big;
  letter-spacing: 0px;
  color: $vineyard-purple-primary;
  opacity: 1;
  font-family: Popins;
}

.subtitle {
  font-size: $vineyard-font-small;
  color: $vineyard-purple-primary;
  margin-bottom: 33px;
}
