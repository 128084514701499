@import "../../../../styles/module-imports";

.icon {
  @extend %icon;
  cursor: pointer;
}

.iconCell {
  @extend %iconCell;
  justify-content: flex-end;
}

.tableCell {
  cursor: pointer;
}

.singleTypeEl {
  &:first-child {
    padding-right: 15px;
  }
  &:last-child {
    padding-left: 15px;
  }
}
