@import "../../styles/module-imports";

.selectContainer {
  width: 271px;
  position: relative;
  margin-right: 15px;
  background: $white;
  z-index: 1;
  border-radius: $vineyard-input-border-radius;

  :global(.MuiInputBase-root.Mui-focused) {
    border-bottom: unset;
  }
  :global(.MuiInput-underline:after) {
    border-bottom: unset;
  }
  :global(.MuiInput-underline:before) {
    border-bottom: unset;
  }
  :global(.MuiInput-underline:hover:not(.Mui-disabled):before) {
    border-bottom: unset;
  }
  :global(.MuiSelect-select) {
    border: 1px solid $grey-border-color;
    border-radius: $vineyard-input-border-radius;
  }
  :global(.MuiSelect-select:focus) {
    border-radius: $vineyard-input-border-radius;
    background: $white;
  }
}

.select {
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;

  .item {
    border-bottom: 1px solid $grey-border-color;
  }

  :global(.MuiInputBase-input) {
    font-size: 12px;
    padding: 7px 20px;
    font-family: Popins;
  }
}

.defaultOption {
  font-weight: bolder !important;
}
:global(.MuiPaper-root) {
  border-radius: 18px !important;
  :global(.MuiMenu-list) {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 18px;
    :global(.MuiMenuItem-root) {
      border-bottom: 1px solid $grey-border-color;

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}

:global(.MuiPaper-rounded) {
  border-radius: 18px;
}
