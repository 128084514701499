@import "../../../../styles/module-imports";

.navWrapper {
  button {
    text-align: center;
    border-bottom: none;
    color: $vineyard-light-primiray;
    cursor: pointer;
    font-size: 14px;

    span {
      padding-left: 25px;
      padding-right: 25px;

      @include xl-large-down {
        padding-left: 10px;
        padding-right: 10px;
      }

      @include x-large-down {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    @include medium-up {
      padding-left: 25px;
      padding-right: 25px;

      &:first-child {
        padding-left: calc(1.5rem + 15px);
      }

      &:last-child {
        padding-right: 25px;
      }
    }

    @include xl-large-down {
      padding-left: 10px;
      padding-right: 10px;
    }

    @include x-large-down {
      padding-left: 5px;
      padding-right: 5px;
      width: 100%;
    }
  }

  button[aria-selected="true"] {
    border-bottom: 3px solid $vineyard-green;
    color: $vineyard-purple-primary;
    font-size: 14px;
    @include medium-up {
      span {
        font-family: "Popins-SemiBold";
      }
    }
    @include x-large-down {
      border-bottom: none;
    }
  }

  span:last-child {
    display: none;
  }
}
