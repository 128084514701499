@import "../../../../styles/module-imports";

.smallSize {
  font-size: $vineyard-font-very-small;
  text-align: center;
}

.mediumSize {
  font-size: $vineyard-font-small;
  text-align: center;
}

.colWrapper {
  border-left: 1px solid $grey-border-color;
}

.borderTop {
  border-top: 1px solid $grey-border-color;
}

.profileImage {
  height: 67px;
  width: 67px;
  border-radius: 50%;
}
