@import "../../../../styles/module-imports";

.courseOverviewHeader {
  display: block;
  @include small-up {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
  }
}

.courseOverviewWrapper {
  display: block;
  @include small-up {
    display: flex;
    padding-left: 15px;
  }
}

.listCourseBaseDeatils {
  @extend %horizontalWrapper;
  justify-content: stretch;
  align-items: center;
  margin-bottom: 8px;

  @include small-down {
    justify-content: space-around;
  }
}

.itemCoruseDetails {
  padding: 0 15px;
  border-left: 1px solid $grey-devider-color;
  line-height: 1em;
  width: 33.3%;
  &:first-child {
    border-left: none;
    padding-left: 0px;
  }
  @include medium-up {
    width: auto;
  }

  @include medium-down {
    text-align: start;
  }
}

.horizontalWrapper {
  display: block;
  @include small-up {
    width: 100%;
    display: flex;
  }
}

.courseDetailsSectionWrapper {
  text-align: start;
  border-left: none;
  align-items: center;
  margin-bottom: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid $vineyard-grey-bg;
  @include medium-up {
    border-left: 1px solid $grey-border-color;
    border-bottom: none;
    margin-bottom: 0px;
    padding-left: 25px;
    padding-right: 25px;
  }
}

.greyFontColor {
  color: #707070;
  font-size: 12px;
}

.scoreOverviewTitle {
  text-align: start;
  padding-left: 30px;
  margin-top: 35px;
  margin-bottom: 15px;
  @include small-down {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 15px;
  }
}

.label {
  font-family: "Popins";
  color: #b9b8ce;
  margin-bottom: 3px;
  font-size: 12px;
  text-align: start;
}

.duration {
  text-align: start;
}

.avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 15px;
  @include small-down {
    width: 38px;
    height: 38px;
    border-radius: 50%;
  }
}

.title {
  text-align: start;

  @include small-down {
    font-size: 14px;
  }
}

.headingWrapper {
  @include x-large-down {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
