@import "../../styles/variables";
@import "../../styles/module-imports";

.buttonWrapper {
  margin-top: $vineyard-button-margin;
}

.btnStyle {
  width: 100%;
  height: 100%;
  color: $white;
  background: $vineyard-purple-primary;
  opacity: 1;
  border: 1px solid $grey-border-color;
  border-radius: 24px;
  font-size: 14px;
  line-height: 14px;
  font-family: Popins;
  padding: 16px 20px;
  font-family: "Popins-SemiBold";
  &:hover {
    opacity: 0.7;
  }
  &:checked {
    opacity: 0.1;
  }
}

.dialog {
  padding: 5px 20px;
  min-width: 110px;
}

button:focus {
  outline: none;
}

.greenBg {
  background: $vineyard-green;
}

.dialogCancelButton {
  background: $white;
  color: $vineyard-purple-primary;
  font-family: "Popins" !important;

  &:hover {
    background: $vineyard-purple-primary;
    color: $white;
    opacity: 0.3;
  }
  &:active {
    background: $vineyard-purple-primary;
    opacity: 0.5;
  }
}

.dialogButtonWrapper {
  width: 100%;
  height: 42px;
}

.smallButtonWrapper {
  width: 142px;
  height: 25px;
}

.extraSmallButtonWrapper {
  @include small-up {
    margin-top: 0px;
  }
}

.smallEditButton {
  color: black;
  background: none;
  border: none;
  font-size: 14px;
  line-height: 14px;
  padding-inline: 10px;
}

.extraSmallButton {
  padding: 13px 10px;
  min-width: 90px;
}

.smallCancelWrapper {
  width: 181px;
  height: 42px;
  margin-top: 0px;
  margin-right: 10px;
}

.smallWithoutPadding {
  padding: 0px;
  font-size: 10px;
  padding: 0 10px 0 10px;
  margin-top: 0px;
}

.deny {
  background: $white;
  color: $vineyard-pink;
  border-color: $vineyard-pink;
  padding: 5px 20px;
}

.denyWrapper {
  width: 100%;
  height: 42px;
}

.hold {
  background: $white;
  color: $vineyard-light-primiray;
  border-color: $vineyard-light-primiray;
  padding: 5px 20px;
}

.holdWrapper {
  width: 100%;
  height: 42px;
}

.denyBtn {
  background: #df2c6f;
}

.purchase {
  &:hover {
    opacity: 1;
    cursor: auto;
  }
}

.selectWrapper {
  margin-top: 0px !important;
}
