@import "../../../../styles/module-imports";

.rightBorder {
  border-right: 1px solid $grey-border-color;
}

.greyFont {
  color: #707070;
}

.topBorder {
  border-top: 1px solid $grey-border-color;
}

.greenText {
  color: $vineyard-green;
}

.commentCard {
  background-color: $grey;
  border-radius: 18px;
  padding: 35px 40px 35px 40px;
}

.profileIcon {
  width: 55px;
  border-radius: 50%;
}

.fileCursor {
  cursor: pointer;
}

.borderBottom {
  border-bottom: 1px solid $grey-border-color;
}

.icon {
  @extend %icon;
  cursor: pointer;
}
