@import "../../../../styles/module-imports";

.wrap {
  display: inline-block;
  width: 100%;
  height: calc(100vh - 200px);
  overflow-y: auto;
}

.progressBar {
  position: absolute;
  top: 50%;
  right: 50%;
  color: $vineyard-purple-primary;
  z-index: 1;
}
