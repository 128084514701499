@import "../../../../styles/module-imports";

.tableWrapper {
  display: inline-block;
  width: 100%;
  height: calc(100% - 332px);
  overflow-y: auto;
}

.headerCell {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.arrow {
  @extend %sortIcon;
}

.table {
  @extend %table;
}
