@import "../../../src/styles/module-imports";

.pickerLabelInput {
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #524a6b;
  opacity: 1;
}

.pickerInputWrapper {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #dfdfdf;
  border-radius: 24px;
  opacity: 1;
  margin-top: 10px;
}

.pickerInputWrapper button {
  border-radius: 40px;
}

.pickerInputWrapper div {
  border-radius: 40px !important;
}
