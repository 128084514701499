@import "../../../../../../styles/module-imports";

.addGroupWrapper {
  width: 100%;
  background: $white;
  border-radius: 18px;
  opacity: 1;

  @include medium-up {
    width: 620px;
  }
}

.label {
  @extend %label;
}

.labelWrapper {
  height: 100%;
  font-size: 14px;
  color: #524a6b;
  opacity: 1;
}

.errorMsg {
  position: absolute;
  font-size: $vineyard-font-very-small;
  color: $vineyard-error;
}

.checkBox {
  background: $vineyard-purple-primary;
  :global(svg[class*="MuiSvgIcon-root"]) {
    color: $vineyard-purple-primary;
  }
}
