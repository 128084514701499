@import "../../../../styles/module-imports";

.headerCell {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.arrow {
  @extend %sortIcon;
}

.table {
  @extend %table;
}

.helperContainerClass {
  background-color: white;
  border: 1px solid #ececec;
  padding-top: 10px;
  td {
    display: table-cell;
    width: 17%;
    vertical-align: middle;
  }

  td:last-child {
    width: 4%;
  }

  img {
    margin-top: 9px;
    margin-left: 9px;
  }
}
