@import "../../styles/common";

.courseDaySelectedWrapper {
  cursor: pointer;
  align-items: center;
  padding: 5px 10px;
  background-color: #9cde39;
  border-radius: 25px;
  margin-right: 5px;
  position: relative;
  flex-basis: auto;
  padding-right: 40px;
  margin-bottom: 13px;
}

.itemTime {
  @extend %label;
  align-self: baseline;
  height: auto;
  width: auto;
  color: $white;
  font-size: 10px;
}

.listItemWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid $grey-devider-color;
}

.addTimeWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.deleteIcon {
  @extend %deleteIcon;
  position: absolute;
  right: 13px;
  top: 8px;
}

.errorMsg {
  position: absolute;
  font-size: $vineyard-font-very-small;
  color: $vineyard-error;
}
