@import "../../../styles/common";

.dialog {
  :global(.MuiDialog-paper) {
    overflow-y: clip;
    min-width: 280px;
    min-height: 220px;
  }
  :global(.MuiDialogTitle-root) {
    background: aliceblue;
  }
}
.closeButton {
  position: absolute !important;
  right: 2px;
  top: 2px;
  color: #e2e2e2;
}
