@import "../../styles/module-imports";

.errorMsg {
  position: absolute;
  font-size: $vineyard-font-very-small;
  color: $vineyard-error;
}

.accName {
  @extend %label;
  height: auto;
}

.rounded4 {
  border-radius: 25px;
  cursor: pointer;
}

.errorWrapper {
  border-color: $vineyard-error;
}
