@import "../../../../styles/module-imports";

.listItem {
  border-bottom: 1px solid $grey-devider-color;
  display: flex;
  align-items: center;
}

.checkBox {
  background: $vineyard-purple-primary;
  :global(svg[class*="MuiSvgIcon-root"]) {
    color: $vineyard-purple-primary;
  }
}
