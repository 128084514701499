@import "./reset";
@import "./_module-imports.scss";
@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
@font-face {
  font-family: "Popins";
  src: url(../assets/fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: "Popins-Regular";
  src: url(../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Popins-Bold";
  src: url(../assets/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: "Popins-Italic";
  src: url(../assets/fonts/Poppins-Italic.ttf);
}

@font-face {
  font-family: "Popins-SemiBold";
  src: url(../assets/fonts/Poppins-SemiBold.ttf);
}

body {
  font-family: Popins !important;
  background: $vineyard-grey-bg !important;

  @media (max-width: 1440px) {
    font-size: 14px;
  }
}
