@import "../../../../../../styles/module-imports";

.headerContainer {
  height: 77px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 77px;
  right: 0px;
  align-items: center;
  background: $white;
  padding: 0 15px 0 15px;
  z-index: 5;

  @include x-large-up {
    width: calc(100% - 250px);
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 999;
    height: auto;
  }
}

.userInfoWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $white;

  @include x-large-up {
    width: auto;
    justify-content: flex-end;
  }
}

.purpleBg {
  background: $vineyard-purple-primary;
  position: relative;
  top: 0px;
}

.userInfo {
  width: 100%;
  font-size: 16px;
  margin: 0 15px 0 15px;
  margin-bottom: 0px;
  color: $vineyard-purple-primary;
  cursor: pointer;
  font-family: "Popins";

  @include x-large-up {
    width: auto;
    margin-right: 25px;
  }
}

.whiteFont {
  color: $white;
}

.profileImage {
  height: 57px;
  width: 57px;
  border-radius: 50%;
  background: $grey;
  cursor: pointer;
}

.childComponentNameWrapper {
  display: none;

  @include x-large-up {
    display: flex;
    align-items: center;
  }
}

.logoutImg {
  cursor: pointer;
}

.headerLabel {
  font-size: 20px;
  color: $vineyard-purple-primary;
  margin: 0px;
  margin-right: 30px;
  padding-right: 30px;
  margin-left: 12px;
  border-right: 1px solid $grey-border-color;
  font-family: "Popins";
}

.locationLabel {
  font-size: 14px;
  color: $vineyard-purple-primary;
}

.section {
  font-size: 12px;
  color: #b8b5c2;
}

.locationWrapper {
  border-right: 1px solid $grey-border-color;
  margin: 0px;
  margin-right: 30px;
  padding-right: 30px;
}
