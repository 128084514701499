@import "../../../../styles/module-imports";

.icon {
  @extend %icon;
  cursor: pointer;
}

.listItemWrapper {
  @extend %horizontalWrapper;
  height: 48px;
  justify-content: baseline;
  align-items: center;
  border-bottom: 1px solid $grey-devider-color;
}

.activityView {
  font-size: $vineyard-font-very-small;
  color: $vineyard-green;
  cursor: pointer;
}

.iconView {
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.smallFont {
  font-size: $vineyard-font-small;
}

.scroll {
  height: calc(100vh - 650px);
  overflow-y: auto;
}

.modal {
  //position: absolute;
  border: 1px solid $grey-devider-color;
  z-index: 1;
  width: 95%;
}

.DatePickerText {
  margin-top: 20px;
  display: inline;
}

.buttonWidth {
  width: 160px;
}
