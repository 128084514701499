@import "../../../styles/module-imports";

.CUActivityWrapper {
  background: $white;
  border-radius: 24px;
}

.courseActivityLimitWrapper {
  align-items: center;
  padding: 5px 10px;
  background-color: #9cde39;
  border-radius: 25px;
  margin-right: 5px;
  display: flex;
  width: auto;
}

.noCursor {
  @extend %label;
  align-self: baseline;
  height: auto;
  width: auto;
  color: $white;
  font-size: 10px;
  cursor: auto;
}

.activityLimit {
  @extend %label;
  align-self: baseline;
  height: auto;
  width: auto;
  color: $white;
  font-size: 10px;
  cursor: pointer;
}

.listLimitWrapper {
  @extend %horizontalWrapper;
  height: 48px;
  justify-content: baseline;
  align-items: center;
  border-bottom: 1px solid $grey-devider-color;
}

.dayErrorText {
  color: $vineyard-error;
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 10px;
}

.deleteIcon {
  @extend %deleteIcon;
  cursor: pointer;
}

.deleteSchedulerIcon {
  @extend %deleteIcon;
  position: absolute;
  right: 13px;
  top: 8px;
}

.accName {
  @extend %label;
  height: auto;
}
.rounded4 {
  border-radius: 25px;
  cursor: pointer;
}
.stepWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
}

input[type="file"] {
  display: none;
}

.icon {
  @extend %icon;
  cursor: pointer;
}

.progressBar {
  position: absolute;
  top: 50%;
  right: 50%;
  z-index: 1;

  :global(.MuiCircularProgress-circle) {
    color: $vineyard-purple-primary;
  }
}

.radioButton {
  background: $vineyard-purple-primary;
  :global(svg[class*="MuiSvgIcon-root"]) {
    color: $vineyard-purple-primary;
  }

  + span {
    font-size: 16px;
    font-family: inherit;

    @include xl-large-down {
      font-size: 14px;
    }
  }
}

.singleTimePckCol {
  &:first-child {
    padding-right: 0px;
    @include medium-down {
      padding-right: 15px;
    }
  }
  &:nth-child(2) {
    padding-right: 0px;
    @include medium-down {
      padding-right: 15px;
    }
  }
}

.datePickerHolder {
  input {
    margin: 0px !important;
    width: 100%;
  }
}

.listItemWrapper {
  @extend %horizontalWrapper;
  height: 48px;
  justify-content: baseline;
  align-items: center;
  border-bottom: 1px solid $grey-devider-color;
}

.sectionListItemWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  align-items: center;
  border-bottom: 1px solid $grey-devider-color;
}

.courseDaySelectedWrapper {
  cursor: pointer;
  align-items: center;
  padding: 5px 10px;
  background-color: #9cde39;
  border-radius: 25px;
  margin-right: 5px;
  display: flex;
  width: auto;
}

.sectionDaySelectedWrapper {
  cursor: pointer;
  align-items: center;
  padding: 5px 10px;
  background-color: #9cde39;
  border-radius: 25px;
  margin-right: 5px;
  position: relative;
  flex-basis: auto;
  padding-right: 40px;
  margin-bottom: 13px;
}

.itemTime {
  @extend %label;
  align-self: baseline;
  height: auto;
  width: auto;
  color: $white;
  font-size: 10px;
}

.errorMsg {
  position: absolute;
  font-size: $vineyard-font-very-small;
  color: $vineyard-error;
}

.customCheckBoxWrapper {
  > div {
    position: relative;
    padding-right: 35px;

    > span {
      position: absolute;
      right: 0px;
      top: 0px;
      padding: 0px;
    }
  }
}

.inputsHolder {
  .singleCol {
    @media (max-width: 1600px) {
      flex: 0 0 50%;
      min-width: 50%;
      margin-bottom: 25px;
    }
    label {
      > div {
        width: 100%;
      }
    }
  }
}

.courseDayEdit {
  @extend .sectionDaySelectedWrapper;
  border: 1px solid $blue-focus;
}

.transform {
  transition-property: max-height;
  transition-duration: 1s;
  overflow: hidden;
  max-height: 0px;
}

.transformed {
  max-height: 100%;
}

.dropdownContainer {
  width: 100%;
  position: absolute;
  z-index: 1;
}

.wrapper {
  // width: 250px;
}
.wrap {
  display: inline-block;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  height: calc(100vh - 200px);
  overflow-y: auto;
  background-color: $vineyard-grey-bg;
}

.multiSelectInput {
  border-radius: 30px !important;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

:global(.react-select__placeholder) {
  font-family: Popins !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 14px;
}

:global(.react-select__control) {
  color: black;
  border-radius: 24px !important;
}

.label {
  font-size: 14px;
  color: #524a6b;
  opacity: 1;
}
