@import "../../styles/module-imports";

.container {
  width: 267px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #625b7d;
  padding: 24px;
  cursor: pointer;

  @include medium-up {
    width: 100%;
  }
}

.title {
  color: $white;
  font-size: $vineyard-font-medium;
  font-family: Popins;
  margin-bottom: 0px;

  @include medium-up {
    font-size: $vineyard-font-small;
  }
}

.icon {
  width: 23px;
  height: 23px;
  margin-right: 15px;

  @include medium-up {
    width: 20px;
    height: 20px;
  }
}
