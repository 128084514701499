@import "../../../../styles/module-imports";

.scheduler {
  font-family: Popins-Regular !important;
  border-radius: 0px;
  @include x-large-up {
    border-radius: 25px !important;
  }
  :global(.e-work-cells) {
    height: 150px !important;
  }

  :global(.e-current-day) {
    color: $vineyard-purple-primary !important;
    font-weight: bold !important;
  }

  :global(.e-current-date) {
    color: $vineyard-purple-primary !important;
    font-weight: bold i !important;
    font-style: italic;
    :global(.e-date-header) {
      background-color: $vineyard-purple-primary !important;
    }
  }

  :global(.e-active-view) {
    :global(.e-tbar-btn-text) {
      color: $vineyard-purple-primary !important;
      font-weight: bold;
    }
  }

  :global(.e-selected) {
    :global(.e-day) {
      background-color: $vineyard-purple-primary !important;
    }
  }

  :global(.e-btn) {
    color: $vineyard-purple-primary !important;
  }

  :global(.e-btn-icon) {
    color: $vineyard-purple-primary !important;
  }

  :global(.e-popup-header) {
    background-color: $vineyard-purple-primary !important;
  }

  :global(.e-appointment) {
    // background-color: $vineyard-purple-primary !important;
    border: 1px solid transparent !important;
    border-radius: 15px !important;
    background: $white !important;
  }
  :global(.e-subject) {
    padding-left: 10px !important;
    font-size: 12px !important;
    line-height: normal !important;
  }
  :global(.e-appointment-details) {
    background: #cd5298;
  }
}

:global(.e-dialog) {
  :global(.e-subject:focus) {
    :global(.e-float-line) {
      background: black !important;
    }
  }

  :global(.e-float-text) {
    color: black !important;
  }

  :global(.e-checkbox-wrapper) {
    display: none !important;
  }

  :global(.e-editor) {
    display: none !important;
  }

  :global(.e-description-row) {
    display: none !important;
  }
}
