@import "../../../../styles/module-imports";

.addButtonInvisible {
  display: none;
}

.addButtonVisible {
  display: contents;
}

.fullWidth {
  width: 100%;
}

.tableMainWrapper {
  display: inline-block;
  width: 100%;
  overflow-y: auto;
}

.headerCell {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.arrow {
  @extend %sortIcon;
}

.table {
  @extend %table;
}
