@import "../../../../styles/module-imports";

.cellWrapper {
  text-align: center;
  width: 200px;
}

.firstCellWrapper {
  width: 200px;
}

.gradebookTable {
  @extend %table;
  thead {
    border: none;
  }
}
