@import "../../styles/module-imports";

.root {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: 18px;

  :global(.MuiInputLabel-root) {
    transform: none !important;
    // font-weight: $weight-bold;
    color: $black;
    font-size: $vineyard-font-small;
    position: absolute;
    top: -18px;
  }

  :global(.MuiAutocomplete-input) {
    margin: 0;
    top: -5px;
  }

  :global(.MuiFormControl-fullWidth) {
    height: 100%;
  }

  :global(.MuiAutocomplete-inputRoot) {
    height: 100%;
    border: 1px solid $grey-border-color !important;
    border-radius: $vineyard-input-border-radius !important;
  }

  :global(.MuiInputBase-root.Mui-focused) {
    border-radius: $vineyard-input-border-radius !important;
    border: 1px solid $grey-border-color !important;
  }
}

.errorMsg {
  position: absolute;
  font-size: $vineyard-font-very-small;
  color: $vineyard-error;
}

.errorWrapper {
  border-color: $vineyard-error;
}

.errorRoot {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: 18px;

  :global(.MuiInputLabel-root) {
    transform: none !important;
    // font-weight: $weight-bold;
    color: $black;
    font-size: $vineyard-font-small;
    position: absolute;
    top: -18px;
  }

  :global(.MuiAutocomplete-input) {
    margin: 0;
    top: -5px;
  }

  :global(.MuiFormControl-fullWidth) {
    height: 100%;
  }

  :global(.MuiAutocomplete-inputRoot) {
    height: 100%;
    border: 1px solid $vineyard-error !important;
    border-radius: $vineyard-input-border-radius !important;
  }

  :global(.MuiInputBase-root.Mui-focused) {
    border-radius: $vineyard-input-border-radius !important;
    border: 1px solid $grey-border-color !important;
  }
}
