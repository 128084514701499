@import "../../../../styles/module-imports";

.modalWrapper {
  background: $white;
  border-radius: 25px;
  padding: 20px;
}

.delete {
  cursor: pointer;
  height: 25px;
  width: 25px;
  padding: 5px;
  background: $vineyard-purple-primary;
  border-radius: 3px;
  &:hover {
    opacity: 0.5;
  }
}
