@import "../../../../styles/module-imports";

.icon {
  @extend %icon;
  cursor: pointer;
}

.listItemWrapper {
  @extend %horizontalWrapper;
  justify-content: baseline;
  align-items: center;
}

.valTerm {
  border-left: 1px solid $vineyard-purple-primary;
  text-align: center;
}

.borderBottom {
  border-bottom: 1px solid $grey-devider-color;
}

.valEdit {
  text-align: center;
  border-bottom: 1px solid $grey-devider-color;
}

.scroll {
  height: calc(100vh - 365px);
  overflow-y: auto;
}
