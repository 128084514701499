@import "../../../../styles/module-imports";

.borderBottom {
  border-bottom: 1px solid $grey-devider-color;
}

.valEdit {
  text-align: center;
  border-bottom: 1px solid $grey-devider-color;
}

.icon {
  @extend %icon;
  cursor: pointer;
}

.valTerm {
  border-left: 1px solid $vineyard-purple-primary;
  font-size: $vineyard-font-small;
  text-align: center;
}

.modal {
  position: absolute;
  border: 1px solid $grey-devider-color;
  z-index: 1;
  width: 95%;
}

.progressBar {
  :global(.MuiCircularProgress-circle) {
    color: $vineyard-purple-primary;
  }
}

.greenText {
  color: $vineyard-green;
}
