@import "../../../../styles/module-imports";

.container {
  height: 100vh;
  background: $grey;
}

.navContainer {
  width: 100%;
  height: 77px;
  position: fixed;
  z-index: 10;
  padding: 0;
  background: $vineyard-purple-primary;

  @include x-large-up {
    width: 250px;
    max-width: 250px;
    left: 0px;
    top: 0px;
    height: 100vh;
  }
}

.childWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  top: 82px;

  @include x-large-up {
    margin-top: 0px;
    top: 0;
    overflow: auto;
  }

  @include x-large-down {
    padding: 0 !important;
  }

  @include medium-down {
    // background: $white;
    border-radius: 25px;
  }
}

.contentContainer {
  @include x-large-up {
    padding-left: 250px;
    padding-top: 77px;
    padding-right: 0px;
    // height: 100vh;
    overflow: hidden;
  }
}
