@import "../../../../styles/module-imports";

.colorGreen {
  color: $vineyard-green;
}
.colorRed {
  color: red;
}

.tableSpace {
  padding-inline: 40px;
  text-align: center;
}

.spacing {
  display: flex;
  height: 40px;
  align-items: center;
}

.contentWrapper {
  border-right: 1px solid $grey;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.nameWrapper {
  flex-direction: column;
}
.textWrapperStyle {
  font-size: 12px;
  color: #b5b4cb;
  font-weight: 100;
}

.tableWrapper {
  display: flex;
}

.tableWrapper td {
  padding: 30px;
}

.tableInnerHolder {
  margin-left: 35px;
}

.titlePointStyle {
  color: #a9cf26;
  padding-left: 10px;
}

.tableHolder:nth-child(2n) {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  @include x-large-down {
    background: $white;
  }
}

.diffPoints {
  color: #a9cf26;
  margin-left: 10px;
}

.diffPointsNegative {
  color: #e62592;
  margin-left: 10px;
}

.icon {
  width: 45px;
  border-radius: 50%;
}

.nameFont {
  font-size: 14px;
  font-family: "Popins";
}
