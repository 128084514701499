@import "../../../../styles/module-imports";

.contentWrapper {
  max-width: 10px;
}

.buttonWrapper {
  max-width: 50px;
}

.buttonPadding {
  padding-bottom: 20px;
}

.avatarIcon {
  width: 55px;
  border-radius: 50%;
}
