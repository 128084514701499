@import "../../../styles/module-imports";

.scoreBoardContainer {
  border-left: none;
  margin-bottom: 15px;
  padding-bottom: 15px;
  @include medium-up {
    border-left: 1px solid $grey-devider-color;
    border-bottom: none;
    padding-left: 45px;
    margin-bottom: 0px;
  }
}

.scoreBoardFirstElement {
  padding-left: 15px;
  border-bottom: 1px solid $vineyard-grey-bg;
  @include medium-up {
    border-left: none;
    border-bottom: none;
    padding-right: 45px;
    padding-left: 30px;
  }
}

.scoreLabel {
  text-align: center;
  font-size: $vineyard-font-small;
  @include small-down {
    font-size: 10px;
  }
}

.scoreValue {
  text-align: center;
  font-size: 22px;
  color: $vineyard-green;
  font-weight: 600;

  @include small-down {
    font-size: 18px;
    display: inline;
  }
}

.singleScoreCol {
  padding: 0px 45px;
  &:first-child {
    padding-left: 15px;
  }

  @include xl-large-down {
    padding: 0px 15px;

    &:first-child {
      padding-left: 15px;
    }
  }

  @include medium-down {
    width: 25%;
  }
  @include small-down {
    width: 50%;
  }
}

.hoverValue {
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  border-radius: 5px;
  padding-top: 50px;

  p {
    border-radius: 5px;
    background-color: $vineyard-purple-primary;
    color: $white;
    padding: 0px 5px;
  }
  &:hover {
    opacity: 1;
  }
}

.title {
  @include small-down {
    text-align: start;
  }
}

.popup {
  padding: 10px;
}
