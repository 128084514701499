@import "../../../styles/module-imports";

.icon {
  @extend %icon;
  cursor: pointer;
}

.iconCell {
  @extend %iconCell;
  justify-content: flex-end;
}
