@import "../../../../styles/module-imports";

.headerCell {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.arrow {
  @extend %sortIcon;
}

.table {
  @extend %table;
}
