@import "../../../../styles/module-imports";

.addGroupWrapper {
  width: 620px;
  background: $white;
  border-radius: 18px;
  opacity: 1;
}

.card {
  width: 400px;
  height: 315px;
  display: flex;
  background-color: white;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 3px 6px #0000001f;
  border-radius: 18px;

  opacity: 1;
}

.description {
  text-align: center;
  font: normal normal 300 14px/24px Poppins;
  letter-spacing: 0px;
  color: $black;
  opacity: 1;
}

.price {
  text-align: left;
  font: normal normal medium 16px/37px Poppins;
  letter-spacing: 0px;
  opacity: 1;
}

.sum {
  width: 33px;
  height: 36px;
  color: $vineyard-pink;
  opacity: 1;
  font-size: 22px;
}
