@import "../../../../styles/module-imports";

.container {
  @include medium-up {
    background: $white;
  }
}

.listWrapper {
  margin-top: 10px;
  min-height: 800px;
  padding-bottom: 25px;
  @include medium-up {
    padding-left: 0rem;
    margin-top: 0px;
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    margin-left: 340px;
  }
}

.buttonWrapper {
  align-self: center;
  margin-left: 20px;
}

.listContent {
  display: block;
  @include medium-up {
    display: inline-flex;
  }
}

.wrapper {
  display: block;
  // background: #00000029;
}
