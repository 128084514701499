@import "../../../styles/module-imports";

.label {
  @extend %label;
  font-size: 12px;
  margin-bottom: 10px;
  height: auto;
}

.header {
  font-size: $vineyard-font-large;
  color: $vineyard-purple-primary;
  letter-spacing: 0px;
}

.avatarStyle {
  width: 51px;
  height: 51px;
  cursor: pointer;
}

.avatarPickStyle {
  width: 51px;
  height: 51px;
  margin: 5px;
  cursor: pointer;
}

.datePickerHolder {
  @include medium-down {
    margin-bottom: 25px;
  }
  input {
    margin: 0 0px !important;
    width: 100%;
  }
}

.popupCard {
  max-width: 340px;
  min-width: 150px;
  min-height: 50px;
}

.cursor {
  cursor: pointer;
}

.singleEditCourseCol {
  @include large-down {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 15px !important;
  }
}

.editCourseHolder {
  @include medium-down {
    background-color: #fff;
    border-radius: 24px;
    margin-top: 15px;
  }
}

.editCourseForm {
  @include medium-down {
    padding: 0;
  }
}

.progressBar {
  :global(.MuiCircularProgress-circle) {
    color: $vineyard-purple-primary;
  }
}

.icon {
  margin-left: 5px;
}
