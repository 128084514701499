@import "../../../../styles/module-imports";

.icon {
  @extend %icon;
  // cursor: pointer;
}

.iconCell {
  @extend %iconCell;
  justify-content: flex-end;
}

.tableCell {
  // cursor: pointer;
}

.tableCellProfileImage {
  // cursor: pointer;
  max-width: 30px;
}

.tableCellGreen {
  color: $vineyard-green;
  // cursor: pointer;
}

.redText {
  color: $vineyard-pink;
}

.pendingColor {
  color: #9c4aee;
}

.profileIcon {
  width: 55px;
  border-radius: 50%;
}
