@import "../../../../../../styles/module-imports";

.card {
  @extend %card;
  display: inline-table;
  margin-left: 0rem;
  @include medium-up {
    margin-left: 1.5rem;
  }
}

.studentList {
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: none;
  max-height: 400px;
  @include medium-up {
    max-height: 552px;
    overflow-y: auto;
  }
}

.clearAllWrapperText p {
  font: normal normal normal 12px/37px Poppins;
  color: #b5b1bf;
  cursor: pointer;
}

.studentCard {
  width: auto;
  max-height: 800px;
  background: $white;
  border-radius: 18px;
  opacity: 1;
  box-shadow: 0px 3px 6px #00000014;
  position: relative;
  margin-left: 0rem;

  @include medium-up {
    position: absolute;
    display: inline-block;
    left: 0;
    max-height: 800px;
    background: $white;
    border-radius: 18px;
    opacity: 1;
    box-shadow: 0px 3px 6px #00000014;
    margin-left: 1.5rem;
  }
}

.iconWrapper {
  cursor: pointer;
}

.header {
  justify-content: space-between;
  @include medium-up {
    justify-content: start;
  }
}
