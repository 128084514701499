@import "../../styles/module-imports";

.card {
  width: 100%;
  background: $white;

  @include x-large-down {
    margin-top: 1rem;
    box-shadow: 0px 3px 6px #0000001f;
  }
}

.cardFullScreen {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.cardNoPadding {
  padding-left: 0px;
}

.cardTopPadding {
  margin-top: 40px;
}
