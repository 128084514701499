@import "./functions";

// Variables
//
// Shared variables for everything.  If you are thinking of throwing a constant
// value somewhere, you should think of putting it as a variable here instead.
//
// Please keep naming consistent.  Do not camel case variables and keep them
// logically grouped. Also, make sure to use the !default syntax.
//
// You will also notice how the variable naming is similar to Bootstrap naming
// conventions.  The conventions work well, please think about using them.

// Colors

$white: #ffffff;
$black: #000000;
$grey: #f5f6f7;

$vineyard-grey-bg: #f8f8f8;

$grey-border-color: #dfdfdf;
$grey-devider-color: #e6eaee;

$vineyard-purple-primary: #524a6b;
$vineyard-light-primiray: #b5b4cb;
$vineyard-green: #9cde39;
$vineyard-pink: #e62592;
$vineyard-error: #f54b5e;

$blue-focus: #3869ca;

// Body
$body-bg: $white !default;
$body-color: $black !default;

// Spacing
$spacer: 1rem !default;

// Grid
$grid-max-width: 980px;

// Icons
$icon-path: "../../clientlib-common/resources/icons" !default;

// Typography
// $font-path: "../../clientlib-common/resources/fonts" !default;
// $font-family-sans: "ToyotaType", -apple-system, "Segoe UI", Roboto,
//   "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
//   "Segoe UI Symbol", "Noto Color Emoji" !default;
// $font-family-mono: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
//   "Courier New", monospace !default;
// $font-family-base: $font-family-sans !default;

// $font-size-base: 1rem !default;
// $font-size-lg: ($font-size-base * 1.25) !default;
// $font-size-sm: ($font-size-base * 0.875) !default;

// $font-weight-light: 200 !default;
// $font-weight-normal: 400 !default;
// $font-weight-semibold: 600 !default;
// $font-weight-bold: 700 !default;

// $font-weight-base: $font-weight-normal !default;
// $line-height-base: 1.5 !default;

// $h1-font-size: calculateRem(40) !default;
// $h2-font-size: calculateRem(32) !default;
// $h3-font-size: calculateRem(28) !default;
// $h4-font-size: calculateRem(24) !default;
// $h5-font-size: calculateRem(20) !default;
// $h6-font-size: calculateRem(16) !default;

// $heading-margin-bottom: ($spacer / 2) !default;
// $heading-font-family: inherit !default;
// $heading-font-weight: 600 !default;
// $heading-line-height: 1 !default;
$heading-color: inherit !default;

// $paragraph-margin-bottom: 1rem !default;

$small-font-size: 80% !default;

// Default transition
$trans-timing: 300ms;
$trans-timing-function: cubic-bezier(0, 0, 1, 1);

$vineyard-default-height: 48px;

$vineyard-button-margin: 15px;
$vineyard-text-input-margin: 24px;

$vineyard-font-very-small: 12px;
$vineyard-font-small: 14px;
$vineyard-font-medium: 16px;
$vineyard-font-medium-large: 18px;
$vineyard-font-large: 20px;
$vineyard-font-big: 24px;

$vineyard-desktop-border-radius: 20px;
$vineyard-mobile-border-radius: 13px;

$vineyard-input-border-radius: 24px;
