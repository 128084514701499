@import "../../../../styles/module-imports";

.titleValueWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Popins";
}

.avatar {
  width: 22px;
  height: 22px;
  margin-right: 10px;

  @include x-large-down{
    width: 37px;
    height: 37px;
  }
}

.textGreen {
  color: $vineyard-green;
}

.dayMinWidth {
  min-width: 90px;
}

.tableCell {
  cursor: pointer;
}
