@import "../../../../../../styles/module-imports";

.listItem {
  width: 100%;
  border-bottom: 1px solid $grey-devider-color;
  display: flex;
  align-items: center;
}

.icon {
  @extend %iconWithCursor;
  margin-left: 5px;
}

.avatarIcon {
  width: 45px;
  border-radius: 50%;
}

.nameFont {
  font-size: 14px;
}
