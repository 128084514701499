@import "./variables";
@import "./breakpoints";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: "Popins-Regular";
}

thead {
  * {
    font-family: "Popins-SemiBold";
  }
}

ul {
  list-style: none;
  display: flex;
}

body {
  margin: 0;
  font-family: "Popins-Regular";
  background-color: $vineyard-grey-bg;
}

h2 {
  font-size: $vineyard-font-large;
  color: $vineyard-purple-primary;
  font-family: "Popins-Bold";
}

p {
  color: $vineyard-purple-primary;
  /* font-size: $vineyard-font-medium; */
}

h3 {
  font-size: $vineyard-font-medium-large;
  color: $vineyard-purple-primary;
  border-bottom: 1px solid $grey;
  margin: 20px 0 30px 0;
  font-family: "Popins-Bold";
}

h4 {
  font-size: $vineyard-font-medium;
  color: $vineyard-purple-primary;
  font-family: "Popins-SemiBold";
}

table {
}

//schedule
.e-date-header {
  font-family: "Popins";
}


// [tabindex="-1"]:focus {
//   outline: 0 !important;
// }
