@import "../../../styles/module-imports";

.navField {
  text-align: center;
  border-bottom: none;
  color: $vineyard-light-primiray;
  cursor: pointer;
  font-size: 16px;
  span {
    padding-left: 25px;
    padding-right: 25px;
    @include xl-large-down {
      padding-left: 10px;
      padding-right: 10px;
    }
    @include x-large-down {
      padding-left: 5px;
      padding-right: 5px;
    }
    @include large-down {
      font-size: 14px;
    }
  }

  @include medium-up {
    padding-left: 25px;
    padding-right: 25px;
    &:first-child {
      padding-left: calc(1.5rem + 15px);
    }
    &:last-child {
      padding-right: 25px;
    }
  }
  @include xl-large-down {
    padding-left: 10px;
    padding-right: 10px;
  }
  @include x-large-down {
    padding-left: 5px;
    padding-right: 5px;

    width: 100%;
  }
}

.selectedNavField {
  border-bottom: none;
  color: $vineyard-purple-primary;
  font-size: 16px;
  border-bottom: 3px solid $vineyard-green;
  @include medium-up {
    span {
      font-family: "Popins-SemiBold";
    }
  }
  @include large-down {
    font-size: 14px;
  }
  @include x-large-down {
    border-bottom: none;
  }
}

.iconNav {
  width: 16px;
  height: 16px;
}

.imageCol {
  display: flex;
  @include x-large-up {
    display: none;
  }
}

.navFiledSmall {
  font-size: 12px;
  color: $vineyard-purple-primary;
  font-family: "Popins-SemiBold";
}

.navHeader {
  background: $white;
  box-shadow: 0px 1px 3px #00000012;
  @include x-large-up {
    border-radius: 0px;
  }
}

.selectedNavFieldSmall {
  font-size: 16px;
  font-family: "Popins-SemiBold";
  color: $vineyard-purple-primary;
}

.contentWrapper {
  background: #00000029;
  // background: $white;
}

.courseDetailsContentWrapper {
}
