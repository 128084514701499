@import "../../../../styles/module-imports";

.header {
  @extend %header;
}

.mainModalWrapper {
  width: 620px;
  background: $white;
  border-radius: 18px;
  opacity: 1;
}
