@import "../../../styles/module-imports";

.logoImage {
  width: 140px;
  height: 225px;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  position: absolute;

  @include medium-down {
    width: 62px;
    height: auto;
  }
}

.backgroundImage {
  cursor: pointer;
  background-image: url("../../../assets/images/background3.jpg");
  height: 167px;
  width: 100%;
  display: flex;
  padding: 0;
  background-position: center center;
  background-size: cover;

  @include small-up {
    height: auto;
  }
}

.formWrapper {
  height: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include small-up {
    height: 100vh;
    margin-top: 0;
  }
}
