@import "../../../../styles/module-imports";

.cursor {
  cursor: pointer;
}

.profilePhoto {
  border-radius: 100%;
  width: 120px;
  height: 120px;
}

.formWrapper {
  width: 100%;
  height: 100%;
  * {
    font-family: "Popins-SemiBold";
  }
  @include medium-down {
    // background-color: $white;
  }
}

.newPasswordRow {
  @include medium-down {
    padding-top: 5px;
    margin-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 15px;
    border-top: 5px solid $vineyard-grey-bg;
    border-bottom: 5px solid $vineyard-grey-bg;
  }
}

.contentWrapper {
  @include small-up {
    display: inline-flex;
  }
}

.avatarWrapper {
  margin-top: 0.5 rem;
  @include small-up {
    margin-top: 3rem;
  }
}

.padding {
  @include small-down {
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 0.3rem !important;
  }
}

.title {
  @include small-down {
    margin-bottom: 0.8rem;
  }
}
