@import "../../../styles/module-imports";

.pagination {
  svg[class*="MuiSvgIcon-root"] {
    color: $vineyard-purple-primary;
  }

  button {
    color: $vineyard-purple-primary;
    font-family: Popins;
  }
}
