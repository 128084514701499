@import "../../../../../../styles/module-imports";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include small-up {
    // height: 100vh;
  }
}

.headerLogo {
  width: 68px;
  height: auto;
  cursor: pointer;

  @include x-large-down {
    margin-left: 10px;
    width: 48px;
  }
}

.navButtonsContainer {
  width: 296px;
  height: 100vh;
  position: absolute;
  background: $vineyard-purple-primary;
  padding: 0 10px 0 10px;
  overflow: hidden;
  transition: left 0.6s;
  z-index: 30;

  @include x-large-down {
    left: -100%;
  }

  @media screen and (max-width: 360px) {
    width: 254px;
  }

  @include x-large-up {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 100px;
  }
}

.navButtonsContainerShift {
  height: 100vh;
  left: 0px;
}

.headerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $vineyard-purple-primary;
  padding: 14px 10px;
  z-index: 10;

  // @include small-up {
  //   justify-content: center;
  //   padding-top: 35px;
  // }

  @include x-large-up {
    justify-content: center;
    padding-top: 50px;
  }

  // @media only screen and (min-width: 576px) and (max-width: 768px) {
  //   justify-content: space-between;
  //   padding-top: 15px;
  // }
}

.menuButton {
  width: 36px;
  height: 24px;

  @include x-large-up {
    display: none;
  }
}

.UserHeaderWrapper {
  padding-left: 15px;
  @include x-large-up {
    display: none;
  }
}
