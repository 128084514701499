@import "../../styles/module-imports";

.hasError {
  :global(.MuiInputBase-root) {
    border-color: red !important;
    border-style: solid !important;
  }
}

.errorMsg {
  color: red;
  font-size: 10px;
  position: absolute;
  left: 0;
  bottom: -20px;
}

.datePicker {
  background-color: $white;
  width: 100%;
}

.datePicker input {
  margin-bottom: 0px !important;
  background-color: $white !important;
  text-align: center;
}

.datePicker [class*="MuiInputAdornment-positionEnd"] {
  margin-left: 0px;
  position: absolute;
  right: 0px;
}

svg[class*="MuiSvgIcon-root"] {
  color: $grey-border-color;
}

.datePicker [class*="MuiInputBase-root"] {
  height: 42px;
  width: 100%;
  margin-top: 10px;
  border-radius: 24px;
  border: 1px solid $grey-border-color;
  font-family: Popins;
  font-size: 14px;
  padding: 10px 10px;
  padding-right: 40px;
  position: relative;
}

.datePicker [class*="MuiFilledInput-input"] {
  color: $white !important;
}

.datePicker [class*="MuiInput-underline"]:before {
  content: none;
}

:global(.MuiPickersDay-dayDisabled p) {
  color: $grey-border-color;
}

// :global(.MuiPickersDay-daySelected:hover) {
//   background-color: $dark !important;
// }

:global(.MuiPickersDay-daySelected:hover p) {
  color: $white !important;
}

.tableDate {
  div[class*="MuiInputBase-root"] {
    height: 32px;
  }

  div[class*="MuiInputBase-root"] {
    border: 1px solid $grey-border-color;
  }

  input {
    margin-bottom: 0px;
  }

  // :global(.MuiInputBase-root input) {
  //   height: 17px;
  // }
}
:global(.MuiFormControl-root) {
  width: 100% !important;
}

.datePicker {
  :global(.MuiFormLabel-root) {
    font-size: 10px;
    color: $vineyard-green;
    z-index: 1;
  }

  :global(.MuiInputLabel-formControl) {
    transform: translate(13px, 31px) scale(1);
  }

  :global(.MuiInputLabel-shrink) {
    transform: translate(12px, 21px) scale(0.75);
  }

  :global(.MuiInputBase-root input) {
    margin: 0 15px;
  }

  :global(.MuiFormLabel-root.Mui-focused) {
    color: $vineyard-green;
  }

  :global(.MuiInputBase-root input) {
    top: 6px;
  }

  :global(.MuiFormLabel-root.Mui-error) {
    color: $vineyard-green;
  }

  :global(.MuiInputBase-root.Mui-focused) {
    border: 1px solid $grey-border-color;
  }

  :global(.MuiInput-underline:after) {
    border-bottom: unset;
  }
}

.noLabel {
  :global(.MuiInputBase-root input) {
    top: 0px;
  }
}

.label {
  @extend %label;
  position: relative;
  font-size: 14px;
}

.horizontalWrapper {
  @extend %horizontalWrapper;
  width: auto;
  align-items: center;
}

.errorMsg {
  position: absolute;
  font-size: $vineyard-font-very-small;
  color: $vineyard-error;
}

.errorWrapper [class*="MuiInputBase-root"] {
  border-color: $vineyard-error !important;
}
