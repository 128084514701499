@import "../../../../styles/module-imports";

.icon {
  @extend %icon;
  cursor: pointer;
  float: right;
}

.buttonMainWrapper {
  display: inline-block;
}

.mainTitle {
  font-size: 20px;
  font-weight: 600;
  color: #524a6b;
  line-height: 37px;
}

.tableWrapper {
  color: #484756;
  font-size: 12px;
  font-weight: 600;
}

.tableWrapper td {
  padding: 20px;
}

.mainTableHolder td {
  padding: 20px;
}

.diffPoints {
  color: #a9cf26;
  margin-left: 10px;
}

.diffPointsNegative {
  color: #e62592;
  margin-left: 10px;
}

.mainTable {
  max-width: 600px;
}
