@import "../../../../styles/module-imports";

.headerCell {
  cursor: pointer;
}

.arrow {
  @extend %sortIcon;
}

.table {
  @extend %table;
}
