@import "../../styles/module-imports";

.input {
  margin-top: 10px;
  width: 100%;
  height: 120px;
  padding: 10px 20px;
  border: 1px solid $grey-border-color;
  border-radius: $vineyard-input-border-radius;
  opacity: 1;
}

textarea:focus {
  outline: none;
}

.label {
  @extend %label;
}
