@import "../../../../styles/module-imports";

.courseOverviewWrapper {
  display: block;
  @include small-up {
    display: flex;
    color: red;
  }
}

.nameFont {
  font-size: 22px;
}

.mediumFont {
  font-size: 18px;
  font-weight: 500;
}
.listLimitWrapper {
  @extend %horizontalWrapper;
  height: 30px;

  justify-content: baseline;
  align-items: center;
  border-bottom: 1px solid $grey-devider-color;
}

.courseDetailsSectionWrapper {
  height: 100%;
  @include medium-up {
    border-left: 1px solid $grey-border-color;
  }
  @include x-large-down {
    // border-bottom: 1px solid $grey-border-color;
  }
}

.greyFontColor {
  color: #707070;
}

.greenText {
  padding-left: 10px;
  color: $vineyard-green;
  // font-size: 18px;
  // font-weight: 500;
  @include medium-down {
    padding-left: 0px;
  }
}

.icon {
  @extend %icon;
  cursor: pointer;
}
.textColorGreen {
  color: $vineyard-green;
}
.iconCell {
  @extend %iconCell;
  justify-content: flex-end;
  margin-top: 1.5rem;
}

.fileCursor {
  cursor: pointer;
}

.formWrapper {
  border-top: 1px solid $grey-border-color;
}

.borderRight {
  border-right: 1px solid $grey-border-color;
}

.accName {
  @extend %label;
  height: auto;
}

.tableCellWidth {
  width: 20%;
}

.rounded4 {
  border-radius: 25px;
  cursor: pointer;
}
.cursorPointer {
  cursor: pointer;
}

.listLimitWrapper {
  @extend %horizontalWrapper;
  justify-content: baseline;
  padding: 3px;
  align-items: center;
}

.courseActivityLimitWrapper {
  align-items: center;
  padding: 5px 10px;
  background-color: #9cde39;
  border-radius: 25px;
  margin-right: 5px;
  display: flex;
  width: auto;
}

.noCursor {
  @extend %label;
  align-self: baseline;
  height: auto;
  width: auto;
  color: $white;
  font-size: 10px;
  cursor: auto;
}

.activityLimit {
  @extend %label;
  align-self: baseline;
  height: auto;
  width: auto;
  color: $white;
  font-size: 10px;
  cursor: pointer;
}

.deleteIcon {
  @extend %deleteIcon;
  cursor: pointer;
}

.progressBar {
  position: absolute;
  top: 50%;
  right: 50%;
  color: $vineyard-purple-primary;
  z-index: 1;
}

.maxContent {
  max-width: max-content;
}

.listItemWrapper {
  @extend %horizontalWrapper;
  height: 48px;
  justify-content: baseline;
  align-items: center;
  border-bottom: 1px solid $grey-devider-color;
}

.courseDaySelectedWrapper {
  cursor: pointer;
  align-items: center;
  padding: 5px 10px;
  background-color: #9cde39;
  border-radius: 25px;
  margin-right: 5px;
  display: flex;
  width: auto;
}

.itemTime {
  @extend %label;
  align-self: baseline;
  height: auto;
  width: auto;
  color: $white;
  font-size: 10px;
}

.errorMsg {
  position: absolute;
  font-size: $vineyard-font-very-small;
  color: $vineyard-error;
}

.singleCol {
  padding-left: 45px;
  padding-right: 45px;

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 15px;
  }
  h2 {
    line-height: 1em;
  }

  @include medium-down {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.editIconWrapper {
  padding-left: 80px;
}

.SectionWrapper {
  display: flex;
  border-bottom: 1px solid #dfdfdf;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.overflow {
  overflow-y: 100%;
  min-height: 215px;
}

.validationTermText {
  color: red;
  font-weight: bold;
}

.listText {
  display: inline-block;
}
