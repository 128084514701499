@import "../../styles/module-imports";

.card {
  margin-top: 0rem !important;
  width: 100%;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;

  border-radius: $vineyard-mobile-border-radius;
  @include medium-up {
    background: $white;
    margin-top: 1.5rem !important;
  }

  @include small-up {
    border-radius: $vineyard-desktop-border-radius;
  }

  @include x-large-down {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}

.mobileCourses {
  @include medium-down {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.profileEdit {
  @include medium-down {
    background: $white;
  }
}

.cuUserContainerPadding {
  padding: 74px 87px 53px 62px;
}

.cardFullWidth {
  width: 100%;
  border-radius: 0px;
  min-height: 600px;
  form {
    * {
      font-family: "Popins-SemiBold";
    }
  }
  @include x-large-down {
    background-color: #f5f6f7;
  }
}

.tableFullWidth {
  display: inline-table;
}

.cardItem {
  // margin-left: 15px;
  // margin-bottom: 15px;
  // max-width: 98%;
}

.navigationColor {
  background-color: transparent;
}

.courseDetailsHolder {
  margin-top: 0px !important;
  background: #ffff;

  @include x-large-down {
    background: $vineyard-grey-bg;
  }
}

.details {
  background: $white;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
