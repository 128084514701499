@import "../../../../styles/module-imports";

.assignmentMainContent {
  background: #f5f6f782 0% 0% no-repeat padding-box;
  padding: 0px;
  width: 250px;
}

.activityMainTitle {
  color: #524a6b;
  font-weight: 600;
}

.selectInputWrapper {
  display: inline-block;
  margin-left: 30px;
}
