@import "../../../../styles/module-imports";

.card {
  width: 290px;
  display: flex;
  background-color: white;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 3px 6px #0000001f;
  border-radius: 18px;
  max-height: 416px;
  min-height: 350px;
  opacity: 1;
}

.description {
  max-height: 100px;
}

.price {
  text-align: left;
  font: normal normal medium 16px/37px Poppins;
  letter-spacing: 0px;
  opacity: 1;
}

.tokens {
  font-family: "Poppins";
  font-size: 16px;
}

.sum {
  width: 33px;
  height: 36px;
  color: $vineyard-pink;
  opacity: 1;
  font-size: 22px;
  text-align: center;
  // font-family: "Poppins";
}

.purchased {
  opacity: 0.3;
}
