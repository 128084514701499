@import "../../../../../../styles/module-imports";

.card {
  margin-top: 10px;
  @extend %card;
  width: 100%;
  @include medium-up {
    display: inline-table;
    margin-top: 0px;
    width: 320px;
    margin-left: 1.5rem;
  }
}

.studentListPrimary {
  flex-direction: column;
  overflow: auto;
  max-height: 400px;
  @include medium-up {
    max-height: 660px;
  }
}

.colorLabel {
  width: 60px;
  height: 20px;
  border-radius: 10px;
}

.icon {
  @extend %iconWithCursor;
  margin-left: 5px;
}

.iconWrapper {
  cursor: pointer;
}

.groupName {
  font-size: 14px;
}
