@import "../../../../styles/module-imports";

.headerCell {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.arrow {
  @extend %sortIcon;
}

.table {
  @extend %table;
  // @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) {
  //   tr td {
  //     width: 25%;
  //   }
  // }
  td {
    @include x-large-down {
      width: 50% !important;

      &:first-child {
        width: 100% !important;
      }
      &:last-child {
        border: none !important;
        width: 100% !important;
      }
    }
  }
}

.cardBodyMobile {
  @include medium-down {
    padding: 0 !important;
  }
}
