@import "../../../../styles/module-imports";

.icon {
  @extend %icon;
  cursor: pointer;
  margin: 0 auto;
  display: table;
}

.tableBorderWrapper {
  padding: 5px;
  position: relative;
}

.tableBorderWrapper::before {
  content: "";
  background-color: #e7e7e7;
  position: absolute;
  right: 100%;
  top: 3px;
  bottom: 3px;
  width: 1px;
}

.tableBorderWrapper::after {
  content: "";
  background-color: #e7e7e7;
  position: absolute;
  left: 100%;
  top: 3px;
  bottom: 3px;
  width: 1px;
}
