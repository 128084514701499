@import "../../../../styles/module-imports";

.iconSize {
  width: 20px;
  height: 20px;
  margin-top: 5px;
}

.selectedNameWrapper {
  background-color: $vineyard-green;
}

.pointer {
  cursor: pointer;
}
