@import "../../../../styles/module-imports";

.iconsWrapper {
  @extend %iconCell;
  justify-content: flex-end;
}

.icon {
  @extend %iconWithCursor;
  margin-left: 15px;
}

.iconWihtCursor {
  @extend %iconWithCursor;
}

.tableFontPositive {
  color: $vineyard-green;
  border-right: 1px solid $grey-border-color;
  font-family: "Popins-SemiBold";
}

.tableCell {
  text-align: center;
  cursor: pointer;
}

.tableFont {
  border-right: 1px solid $grey-border-color;
}

.fontColorPositive {
  cursor: pointer;
  text-align: center;
  color: $vineyard-green;
}

.fontColorNegative {
  cursor: pointer;
  text-align: center;
  color: $vineyard-pink;
}

.titleText {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  p {
    font-family: "Popins";
  }
}

.handle {
  margin-right: 10px;
  padding: 0 5px 11px 0;
  cursor: grab;
}

td[data-label="Title"],
td[data-label="Credits"] {
  font-family: "Popins";
}

.assignmentsLastTd {
  @include x-large-down {
    width: 50% !important;
  }
}

.handleWrapper {
  display: flex;
  border-right: 1px solid #dfdfdf;
}
