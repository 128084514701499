@import "../../../../styles/module-imports";

.icon {
  @extend %icon;
  cursor: pointer;
}

.iconCell {
  @extend %iconCell;
  justify-content: flex-end;
}

.fileIcon {
  @extend %icon;
  width: 25px;
  height: 25px;
}

.clickableFileIcon {
  @extend %icon;
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.tableCell {
  cursor: pointer;
}

.tableCellCb {
  cursor: pointer;
  max-width: 20px;
}

.greenText {
  color: $vineyard-green;
}

.redText {
  color: $vineyard-pink;
}

.pendingColor {
  color: #9c4aee;
}
