@import "../../../../styles/module-imports";

.header {
  @extend %header;
}

.assignmentWrapper {
  padding: 20px;
  border: 1px solid #f7f7f7;
  cursor: pointer;
  color: #484756;
  font-size: 14px;
  position: relative;
  padding-right: 30px;
}

.assignmentWrapperOnClickStyle {
  background: #a9cf26;
  color: white;
  position: relative;
  padding-right: 30px;
  padding: 20px;
  border: 1px solid #f7f7f7;
  cursor: pointer;
  font-size: 14px;
}

.iconSize {
  width: 15px;
  height: 15px;
  margin-top: 5px;
  position: absolute;
  right: 10px;
  top: 42%;
  transform: translateY(-50%);
}

.section {
  font-size: 12px;
  color: #b8b5c2;
}

.locationWrapper {
  border-right: 1px solid $grey-border-color;
  margin: 0px;
  margin-right: 30px;
  padding-right: 30px;
}
