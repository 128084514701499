@import "../../styles/module-imports";

.textInputWrapper {
  border-radius: $vineyard-input-border-radius;
  background: $white;

  @include medium-down {
    background-color: transparent;
  }
}

.input {
  width: 100%;
  padding: 10px 20px;
  border: 1px solid $grey-border-color;
  border-radius: $vineyard-input-border-radius;
  opacity: 1;
  font-family: "Popins-Regular"!important;
}

input[type="date" i]::-webkit-calendar-picker-indicator {
  filter: opacity(0.3);
  cursor: pointer;
}
input[type="time" i]::-webkit-calendar-picker-indicator {
  filter: opacity(0.3);
  cursor: pointer;
}

.inputEdit {
  border: 0;
  border-radius: 0;
  width: 100%;
  height: $vineyard-default-height;
  background-image: url(../../assets//images/edit.svg);
  background-position: 100% 90%;
  border-bottom: 1px solid #dfdfdf;
  opacity: 1;
  display: inline-block;
  vertical-align: bottom;
  background-repeat: no-repeat;
}

.inputFile {
  border-radius: 1;
  width: 100%;
  background-image: url(../../assets//images/file-svg.svg);
  background-position: 95% 50%;
  border: 1px solid #dfdfdf;
  opacity: 1;
  display: inline-block;
  background-repeat: no-repeat;
}

input:focus {
  outline: none;
}

.label {
  @extend %label;
}

.labelMargin {
  margin-top: 10px;
}

.searchWrapper {
  margin-top: 0px;
  margin-right: 10px;
}

.searchInput {
  background: url("../../assets/images/search_small.svg") no-repeat;
  padding: 7px 20px;
  background-position: 94% center;
  height: auto;
  font-size: 12px;
    @include medium-down {
    margin-bottom: 20px;
  }
}

.loginInputWrapper {
  width: 295px;
  margin-top: $vineyard-text-input-margin;
  border-radius: $vineyard-input-border-radius;
  background: $white;
}

.errorMsg {
  position: absolute;
  font-size: $vineyard-font-very-small;
  color: $vineyard-error;
}

.errorWrapper {
  border-color: $vineyard-error;
}

.validationEdit {
  padding-top: 13px;
  padding-bottom: 13px;
}
