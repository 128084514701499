@import "../../../../styles/module-imports";

.icon {
  @extend %icon;
  cursor: pointer;
}

.iconCell {
  @extend %iconCell;
  justify-content: flex-end;
}

.active {
  color: $vineyard-green;
}

.inactive {
  color: $vineyard-error;
}

.tableCell {
  cursor: pointer;
}

.singleTypeEl {
  &:first-child {
    padding-right: 15px;
  }
  &:last-child {
    padding-left: 15px;
  }
}
