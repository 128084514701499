@import "../../../../styles/module-imports";

.borderBottom {
  border-bottom: 1px solid $grey-devider-color;
}

.progressBar {
  :global(.MuiCircularProgress-circle) {
    color: $vineyard-purple-primary;
  }
}
