@import "../../../../styles/module-imports";

.mainButtonWrapper {
  display: inline-block;
}

.mainButtonWrapper button {
  padding: 10px 40px;
}

.mainTableHolder th {
  text-align: center;
}
