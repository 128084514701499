@import "../../../../styles/module-imports";

.studentCard {
  position: absolute;
  display: inline-block;
  max-height: 450px;
  background: $white;
  border-radius: 18px;
  opacity: 1;
  box-shadow: 0px 3px 6px #00000014;
  z-index: 2;
  width: 100%;
  left: 0;
  @include small-up {
    left: 30%;
    min-width: 310px;
    max-width: 360px;
  }
}

.studentList {
  flex-direction: column;
  overflow-y: auto;
  overflow-x: none;
  max-height: 330px;
}

.clearAllWrapperText p {
  font-size: 12px;
  line-height: 32px;
  color: #b5b1bf;
  cursor: pointer;
}

.closeWrapperText p {
  color: #b5b1bf;
  cursor: pointer;
}
