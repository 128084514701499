@import "../../../../styles/module-imports";

.courseOverviewWrapper {
  display: block;
  @include small-up {
    display: flex;
    height: 80px;
  }
}

.courseDetailsSectionWrapper {
  height: 100%;

  @include x-large-up {
    border-left: 1px solid $grey-border-color;
  }
}

.label {
  font-family: "Popins";
  color: #b9b8ce;
  margin-bottom: 3px;
  font-size: 12px;
  text-align: start;
  margin-top: 0rem;
  @include x-large-down {
    margin-top: 1rem;
  }
}

.values {
  text-align: left;
}
