@import "../../styles/module-imports";

.root {
  position: relative;
}

.hasError {
  :global(.MuiInputBase-root) {
    border-color: $vineyard-error !important;
    border-style: solid !important;
  }
}

.errorMsg {
  position: absolute;
  font-size: $vineyard-font-very-small;
  color: $vineyard-error;
}

.timePicker {
  :global(.MuiInputBase-root.Mui-focused) {
    border: 1px solid $grey-border-color;
    border-radius: $vineyard-input-border-radius;
  }

  :global(.MuiIconButton-root) {
    padding: 3px !important;
  }

  :global(.MuiInput-underline:after) {
    border-bottom: unset;
  }

  [class*="MuiInput-underline"]:before {
    content: none;
  }

  div[class*="MuiInputBase-root"] {
    margin-top: 10px;
    border: 1px solid $grey-border-color;
    border-radius: $vineyard-input-border-radius;
    padding: 10px 10px 11px;
    position: relative;
  }

  input[class*="MuiInputBase-input"] {
    height: 19px;
    margin-bottom: 0px;
    top: 0px;
    font-size: $vineyard-font-small;
    color: $vineyard-purple-primary;
    padding: 0px;

    + div[class*="MuiInputAdornment-positionEnd"] {
      position: absolute;
      right: 5px;
    }
  }
}

:global(.MuiPaper-root) {
  :global(.MuiPickersClockNumber-clockNumberSelected) {
    color: $white;
  }

  :global(.MuiPickersToolbar-toolbar),
  :global(.MuiPickersClockPointer-pointer),
  :global(.MuiPickersClockPointer-thumb),
  :global(.MuiPickersClockPointer-noPoint),
  :global(.MuiPickersClock-pin) {
    background-color: $vineyard-purple-primary;
  }

  :global(.MuiPickersToolbar-toolbar) {
    height: 70px;
  }

  :global(.MuiPickersClockPointer-thumb) {
    border: 14px solid $vineyard-purple-primary;
  }

  :global(.MuiButton-textPrimary) {
    color: $vineyard-purple-primary;
  }
}

.label {
  @extend %label;
}
