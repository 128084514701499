@import "../../../styles/module-imports";

.filterButtonWrapper {
  max-width: 160px;
  @include x-large-down {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .courseFilterHolder {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
}

.icon {
  width: 20px;
  height: 22px;
}

.searchCoursesToggler {
  transition: all 3s ease-in-out;
}

.addCourseButtonWrapper {
  @include x-large-down {
    padding: 27px 35px;
  }
}

.courseFilterHolder {
  @include x-large-down {
    padding: 19px 15px;
    background-color: $white;
    border-radius: 15px;
    @include medium-down {
      margin: 0 15px;
    }
    * {
      font-family: "Popins-SemiBold";
    }
  }
}

.courseFilterWrapper {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) {
    // background-color: $vineyard-grey-bg;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
  }
}
