@import "../../../../styles/module-imports";

.addStudentRowVisibie {
  // display: contents;
}

.addStudnetRowInvisible {
  display: none;
}

.contentWrapper {
  max-width: 120px;
}

.cancel {
  @extend %sortIcon;
  cursor: pointer;
  width: 20px;
  height: 22px;
  background-color: white;

  &:hover {
    opacity: 0.7;
  }
  &:checked {
    opacity: 0.1;
  }
}

.emailWrapper {
  max-width: 200px;
}

.resend {
  @extend %sortIcon;
  cursor: pointer;
  width: 20px;
  height: 30px;
  background-color: white;
}
