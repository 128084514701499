@import "../../../../styles/module-imports";

.sectionOverviewWrapper {
  display: block;
  @include small-up {
    display: flex;
    height: 80px;
  }

  .sectionDetailsWrapper {
    height: 100%;
    padding-left: 0rem;
    @include x-large-up {
      border-left: 1px solid $grey-border-color;
      padding-left: 3rem;
    }
  }
}

.label {
  font-family: "Popins";
  color: #b9b8ce;
  margin-bottom: 3px;
  font-size: 12px;
  text-align: start;
}

.values {
  text-align: left;
}

.time {
  overflow-y: auto;
  max-height: 85px;
}
