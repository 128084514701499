@import "../../../../styles/module-imports";

.header {
  @extend %header;
  font-size: 22px;
  font-family: "Popins-SemiBold";
}

.forgetPasswordLink {
  margin-top: 15px;
  margin-bottom: 0px;
  text-align: end;
  font-size: 10px;
  letter-spacing: 0px;
  color: $vineyard-purple-primary;
  opacity: 1;
}

.formWrapper {
  height: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include small-up {
    height: 100vh;
    margin-top: 0;
  }
}

.formDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1067px;
}

.academicTermWraper {
  :global(.MuiInputBase-root) {
    width: 295px !important;
  }

  :global(.MuiInputBase-input) {
    padding: 11px 19px !important;
    font-size: 14px !important;
  }
  :global(.MuiSelect-select.MuiSelect-select) {
    background-color: white;
  }

  margin-top: 1.5rem;
}
.link {
  text-decoration: none;
}

.minHeight {
  height: 0;
}

.MuiInputBase-root {
}
